// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-round {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    vertical-align: middle;
    border: 5px solid #0a0a0a;
    border-radius: 50%; /* Makes the radio button round */
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.radio-round:checked {
    background-color: #3CB043;
}
`, "",{"version":3,"sources":["webpack://./src/Pickup_Request/styles/pickupRequest.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,yBAAyB;IACzB,kBAAkB,EAAE,iCAAiC;IACrD,gBAAgB;IAChB,wBAAwB;IACxB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".radio-round {\r\n    width: 1.3em;\r\n    height: 1.3em;\r\n    background-color: white;\r\n    vertical-align: middle;\r\n    border: 5px solid #0a0a0a;\r\n    border-radius: 50%; /* Makes the radio button round */\r\n    appearance: none;\r\n    -webkit-appearance: none;\r\n    outline: none;\r\n    cursor: pointer;\r\n}\r\n\r\n.radio-round:checked {\r\n    background-color: #3CB043;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
