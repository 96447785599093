import React from 'react'
import "./loader.css"

function Loader() {
  return (
    <div className="flex items-center justify-center  h-screen bg-white">
        <div class="spinner"></div>

    </div>
  )
}

export default Loader