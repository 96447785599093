// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
  .signup-container {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .small-devices {
    display: none;
  }

  .desktop {
    display: block;
  }

  .head {
    display: block;
  }

  .width:first-child {
    width: 50%;
  }

  .width:last-child {
    width: 50%;
  }

  .login-container {
    display: none;
  }

  .signup-container {
    display: flex;
    flex-direction: row;
    margin: 0;

    min-height: 100vh;
  }

  .signup-img {
    width: 100%;
    /* height: 400px; */
  }

  .signup-form {
    border: 1px solid #66666680;
    margin-top: 20px;
  }

  .form-data-content {
    border: 1px solid rgba(102, 102, 102, 0.5);
    margin-top: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
    border-radius: 24px;
    min-height: 93vh;
    margin-bottom: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Styles/login.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,SAAS;;IAET,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,2BAA2B;IAC3B,gBAAgB;EAClB;;EAEA;IACE,0CAA0C;IAC1C,gBAAgB;IAChB,wCAAwC;IACxC,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;EACrB;AACF","sourcesContent":["@media only screen and (max-width: 600px) {\n  .signup-container {\n    display: none;\n  }\n}\n\n@media only screen and (min-width: 600px) {\n  .small-devices {\n    display: none;\n  }\n\n  .desktop {\n    display: block;\n  }\n\n  .head {\n    display: block;\n  }\n\n  .width:first-child {\n    width: 50%;\n  }\n\n  .width:last-child {\n    width: 50%;\n  }\n\n  .login-container {\n    display: none;\n  }\n\n  .signup-container {\n    display: flex;\n    flex-direction: row;\n    margin: 0;\n\n    min-height: 100vh;\n  }\n\n  .signup-img {\n    width: 100%;\n    /* height: 400px; */\n  }\n\n  .signup-form {\n    border: 1px solid #66666680;\n    margin-top: 20px;\n  }\n\n  .form-data-content {\n    border: 1px solid rgba(102, 102, 102, 0.5);\n    margin-top: 20px;\n    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);\n    border-radius: 24px;\n    min-height: 93vh;\n    margin-bottom: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
