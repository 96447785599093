// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
    width: 56px;
    height: 56px;
    display: grid;
    border: 4.5px solid #0000;
    border-radius: 50%;
    border-color: #dbdcef #0000;
    animation: spinner-e04l1k 1s infinite linear;
  }
  
  .spinner::before,
  .spinner::after {
    content: "";
    grid-area: 1/1;
    margin: 2.2px;
    border: inherit;
    border-radius: 50%;
  }
  
  .spinner::before {
    border-color: #bef264 #0000;
    animation: inherit;
    animation-duration: 0.5s;
    animation-direction: reverse;
  }
  
  .spinner::after {
    margin: 8.9px;
  }
  
  @keyframes spinner-e04l1k {
    100% {
      transform: rotate(1turn);
    }
  }`, "",{"version":3,"sources":["webpack://./src/Common/Footer/loader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,2BAA2B;IAC3B,4CAA4C;EAC9C;;EAEA;;IAEE,WAAW;IACX,cAAc;IACd,aAAa;IACb,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,2BAA2B;IAC3B,kBAAkB;IAClB,wBAAwB;IACxB,4BAA4B;EAC9B;;EAEA;IACE,aAAa;EACf;;EAEA;IACE;MACE,wBAAwB;IAC1B;EACF","sourcesContent":[".spinner {\n    width: 56px;\n    height: 56px;\n    display: grid;\n    border: 4.5px solid #0000;\n    border-radius: 50%;\n    border-color: #dbdcef #0000;\n    animation: spinner-e04l1k 1s infinite linear;\n  }\n  \n  .spinner::before,\n  .spinner::after {\n    content: \"\";\n    grid-area: 1/1;\n    margin: 2.2px;\n    border: inherit;\n    border-radius: 50%;\n  }\n  \n  .spinner::before {\n    border-color: #bef264 #0000;\n    animation: inherit;\n    animation-duration: 0.5s;\n    animation-direction: reverse;\n  }\n  \n  .spinner::after {\n    margin: 8.9px;\n  }\n  \n  @keyframes spinner-e04l1k {\n    100% {\n      transform: rotate(1turn);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
